import { FC, useState } from 'react'
import cx from 'classnames'

import { Icon } from '../Icon'

type Props = {
    className?: string
    children: React.ReactNode
    defaultText: string
    expandedText?: string

    onCollapse?: () => void
}

export const TextWithToggle: FC<Props> = ({
    className,
    children,
    defaultText,
    expandedText,
    onCollapse,
}) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const toggle = () => {
        if (isExpanded) onCollapse?.()
        setIsExpanded(!isExpanded)
    }

    return (
        <div className="mb-3">
            <div
                style={{ cursor: 'pointer', userSelect: 'none', width: 'fit-content' }}
                onClick={toggle}
                className={cx(
                    'd-flex align-items-center neutral-600',
                    isExpanded ? 'stacked-sm' : undefined
                )}
            >
                <Icon
                    className="inline-sm"
                    icon={isExpanded ? 'ChevronDown' : 'ChevronRight'}
                />
                {isExpanded && expandedText ? expandedText : defaultText}
            </div>
            {isExpanded ? (
                <div className={className} style={{ paddingLeft: '24px' }}>
                    {children}
                </div>
            ) : null}
        </div>
    )
}
