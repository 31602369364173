import { useState, ReactNode } from 'react'
import cx from 'classnames'
import {
    useFloating,
    autoUpdate,
    offset,
    useClick,
    useDismiss,
    useInteractions,
    FloatingFocusManager,
} from '@floating-ui/react'

import { FormContext } from 'lib/hooks/useForm'
import { Label } from '../Label'
import { Input } from '../Input'
import { Button } from '../Button'
import styles from './style.module.scss'
import { InputInfo } from '../InputInfo'

type Variable = {
    name: string // Monto del cobro
    code_key: string // {{monto}}
}

type Props = {
    value: string | undefined
    variables: Variable[]
    className?: string
    label?: string
    help?: ReactNode
    placeholder?: string
    formContext?: FormContext
    error?: string

    onChange: (value: string) => void
    onBlur?: () => void
}

export const InsertableVariableInput = ({
    value,
    variables,
    className,
    label,
    help,
    placeholder,
    formContext,
    error,
    onChange,
    onBlur,
}: Props) => {
    const [showVariableSelector, setShowVariableSelector] = useState(false)

    const { refs, floatingStyles, context } = useFloating({
        placement: 'bottom-start',
        whileElementsMounted: autoUpdate,
        open: showVariableSelector,
        onOpenChange: setShowVariableSelector,
        middleware: [offset(4)],
    })

    const click = useClick(context)
    const dismiss = useDismiss(context)
    const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss])

    const insertVariable = (codeKey: string) => {
        // If the cursor is not focused, go to the end of the input
        const cursorPosition =
            (document.getElementById('insertable-variable-input') as HTMLInputElement)
                ?.selectionStart ||
            value?.length ||
            0
        const newValue =
            (value?.slice(0, cursorPosition) || '') +
            codeKey +
            (value?.slice(cursorPosition) || '')

        onChange(newValue)
        setShowVariableSelector(false)
    }

    return (
        <div className={cx(className)}>
            {label ? <Label required={formContext?.required}>{label}</Label> : null}
            <div className="d-flex gap-2" ref={refs.setReference}>
                <Input
                    id="insertable-variable-input"
                    className="flex-grow-1"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                <div>
                    <Button
                        {...getReferenceProps()}
                        type="button"
                        icon="Plus"
                        variant="secondary"
                        size="sm"
                    />
                </div>
            </div>
            <InputInfo error={error || formContext?.displayError} help={help} />

            {/* Variable selector dropdown */}
            {showVariableSelector ? (
                <FloatingFocusManager
                    context={context}
                    modal={false}
                    initialFocus={-1}
                    returnFocus={false}
                >
                    <div
                        ref={refs.setFloating}
                        style={{ ...floatingStyles, zIndex: 999 }}
                        {...getFloatingProps()}
                        className={cx(styles.variableSelector)}
                    >
                        <p className="fs-14 strong mb-2">Variables Disponible</p>
                        {variables.map((variable) => (
                            <Button
                                type="button"
                                key={variable.code_key}
                                variant="secondaryText"
                                size="sm"
                                className={cx(styles.variableButton, 'd-flex py-2 px-2')}
                                onClick={() => insertVariable(variable.code_key)}
                            >
                                <span className="fs-12 me-2">{variable.name}</span>
                                <span className={cx(styles.variableKey, 'px-2 py-1')}>
                                    {variable.code_key}
                                </span>
                            </Button>
                        ))}
                    </div>
                </FloatingFocusManager>
            ) : null}
        </div>
    )
}
