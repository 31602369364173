import { ReactNode, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { useCombobox } from 'downshift'

import { Label } from 'ds/components/Label'
import { InputInfo } from '../InputInfo'
import { Icon } from '../Icon'
import styles from './style.module.scss'

import { fuzzy } from 'lib/utils/fuzzy'
import { FormContext } from 'lib/hooks/useForm'

type Props = {
    className?: string
    label?: string
    value?: any
    disabled?: boolean
    required?: boolean
    onChange?: (newItem: any) => void
    error?: string
    help?: ReactNode
    placeholder?: string
    items: any[]
    getItemId: (item: any) => string
    itemToString: (item: any) => string
    getItemHelper?: (item: any) => string
    getItemDescription?: (item: any) => string
    suggestedAction?: ReactNode
    formContext?: FormContext
    emptyText?: string
    onCreateItem?: (inputValue: string) => void
    createItemLabel?: string
}

export const SelectInput = ({
    className,
    label,
    value = '',
    disabled,
    required,
    onChange,
    error,
    help,
    placeholder,
    items,
    getItemId,
    itemToString,
    getItemHelper,
    getItemDescription,
    suggestedAction,
    formContext,
    emptyText,
    onCreateItem,
    createItemLabel,
}: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [inputItems, setInputItems] = useState(items)

    useEffect(() => setInputItems(items), [items])

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        selectedItem,
        inputValue,
    } = useCombobox({
        items: inputItems,
        itemToString: (item) => (item ? itemToString(item) : ''),
        isItemDisabled: (item) => (item ? !!item.disabled || !!item.isTitle : false),
        defaultHighlightedIndex: 0, // after selection, highlight the first item.
        selectedItem: value,
        onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
            if (!newSelectedItem || newSelectedItem.disabled || newSelectedItem.isTitle)
                return
            onChange?.(newSelectedItem)
            inputRef.current?.blur()
        },
        onInputValueChange: ({ inputValue }) => {
            const filteredItemsToShow = inputValue
                ? items.filter((item) => fuzzy(itemToString(item), inputValue))
                : items

            setInputItems(filteredItemsToShow)
        },
        stateReducer: (state, actionAndChanges) => {
            const { changes, type } = actionAndChanges
            switch (type) {
                case useCombobox.stateChangeTypes.InputClick:
                    return { ...changes, inputValue: '' }
                case useCombobox.stateChangeTypes.InputBlur:
                    // If inputValue doesn't match selection onBlur, clear it out.
                    if (
                        state.selectedItem &&
                        itemToString(state.selectedItem) !== state.inputValue
                    ) {
                        return {
                            ...changes,
                            inputValue: itemToString(state.selectedItem),
                        }
                    } else return changes
                case useCombobox.stateChangeTypes.FunctionSelectItem:
                    if (changes.selectedItem) return changes
                    // Open menu when hitting the 'x' clear
                    return {
                        ...changes,
                        isOpen: true, // keep the menu open after selection.
                        highlightedIndex: -1,
                    }
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                    if (
                        onCreateItem &&
                        changes.inputValue &&
                        itemToString(changes.selectedItem) !== changes.inputValue
                    ) {
                        onCreateItem(changes.inputValue)
                        inputRef.current?.blur()
                    }
                    return changes

                default:
                    return changes
            }
        },
    })

    const numItems = inputItems.length

    const renderCreateItem = () => {
        if (!onCreateItem) return null
        const loweredInputValue = inputValue?.trim().toLowerCase()

        if (
            !loweredInputValue ||
            items.some((item) => itemToString(item).toLowerCase() === loweredInputValue)
        ) {
            return null
        }

        return (
            <li
                {...getItemProps({
                    item: { isCreateNew: true },
                    index: numItems,
                    className: cx(styles.item, 'ease', {
                        [styles.highlight]: highlightedIndex === numItems,
                    }),
                })}
                onClick={() => {
                    onCreateItem(inputValue)
                    inputRef.current?.blur()
                }}
            >
                <div className="d-flex align-items-center">
                    <div className={cx('flex-fill', styles.itemText)}>
                        {createItemLabel}: {inputValue}
                    </div>
                </div>
            </li>
        )
    }

    const createItem = renderCreateItem()

    return (
        <div className={cx(className, styles.wrapper)}>
            <div>
                {label ? (
                    <Label required={required || formContext?.required}>{label}</Label>
                ) : null}

                <div
                    className={cx(styles.container, 'ease', disabled && styles.disabled)}
                >
                    <div className="d-flex">
                        <div className="flex-fill lh-24">
                            <input
                                {...getInputProps({
                                    placeholder,
                                    autoComplete: 'off',
                                    className: cx(
                                        { [styles.disabled]: disabled },
                                        styles.input,
                                        'ease'
                                    ),
                                    ref: inputRef,
                                    disabled,
                                })}
                            />
                        </div>
                    </div>
                </div>

                <ul className={cx(styles.items, { visible: isOpen })} {...getMenuProps()}>
                    {isOpen && !disabled && numItems
                        ? inputItems.map((item, index) => {
                              const isSelected = selectedItem === item
                              const itemHelp = getItemHelper?.(item)
                              const itemDescription = getItemDescription?.(item)
                              const isTitle = item.isTitle || false

                              return (
                                  <li
                                      {...getItemProps({
                                          index,
                                          item,
                                          className: cx(styles.item, 'ease', {
                                              [styles.highlight]:
                                                  highlightedIndex === index,
                                          }),
                                          style: { paddingLeft: 8 },
                                      })}
                                      key={getItemId(item)}
                                  >
                                      <div
                                          className="d-flex align-items-center"
                                          style={{
                                              paddingLeft: item.depth
                                                  ? item.depth * 8
                                                  : 0,
                                          }}
                                      >
                                          {isTitle ? null : isSelected ? (
                                              <Icon
                                                  icon="RecordCircleFill"
                                                  className="primary-500 inline-sm"
                                              />
                                          ) : (
                                              <Icon
                                                  icon="Circle"
                                                  className="neutral-400 inline-sm"
                                              />
                                          )}
                                          <div
                                              className={cx(
                                                  'flex-fill',
                                                  styles.itemText,
                                                  { [styles.isTitle]: isTitle }
                                              )}
                                          >
                                              {itemToString(item)}
                                              {itemHelp ? (
                                                  <span className="neutral-500">
                                                      {' / '}
                                                      {itemHelp}
                                                  </span>
                                              ) : null}
                                              {itemDescription ? (
                                                  <div className="small">
                                                      {itemDescription}
                                                  </div>
                                              ) : null}
                                          </div>
                                      </div>
                                  </li>
                              )
                          })
                        : null}
                    {isOpen && !disabled && !numItems && !createItem ? (
                        <li className={styles.item}>
                            {emptyText ?? 'No hay mas opciones.'}
                        </li>
                    ) : null}
                    {createItem}
                    {isOpen && !disabled && suggestedAction ? (
                        <li className={styles.item}>{suggestedAction}</li>
                    ) : null}
                </ul>
            </div>
            <InputInfo error={error || formContext?.displayError} help={help} />
        </div>
    )
}
